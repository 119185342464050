import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import Layout from "components/Layout";
import SectionTitle from "components/SectionTitle";
import Image from "next/image";
import React from "react";
import { useTranslation } from "next-i18next";
import aboutMe from "@img/about_me.png";
import { useMediaQuery } from 'hooks/useMediaQuery';

const AboutMeText = () => {
  const { t } = useTranslation();
  const isLessThan768 = useMediaQuery(`(max-width: 767px)`);

  return (
    <Layout>
      <Grid
        mb={{ base: "1.8rem", md: "6.4rem" }}
        gridTemplateColumns={{
          base: "1fr",
          md: `minmax(0, 1fr) minmax(0, 1fr)`,
        }}
        gap={{ base: "2.4rem", md: "5rem" }}
        alignItems="center"
      >
        <GridItem order={{ base: 2, md: 1 }}>
          <Box mb={{ base: "3.2rem", md: "4rem" }}>
            <SectionTitle
              startText={t("about_me.bit")}
              highlightedText={t("about_me.important")}
              endText={t("about_me.about_me")}
            />
          </Box>
          <Box mb="1.6rem">
            <Text textStyle={{ base: "b16", md: "b18" }} color="text.grey">
              {t("about_me.description1")}
            </Text>
          </Box>
          <Text textStyle={{ base: "b16", md: "b18" }} color="text.grey">
            {t("about_me.description2")}
          </Text>
        </GridItem>
        <GridItem
          order={{ base: 1, md: 2 }}
          display="flex"
          justifyContent="center"
        >
          <Image
            src={aboutMe}
            height={isLessThan768 ? 400 : 600}
            alt="me"
            quality={100}
          />
        </GridItem>
      </Grid>
    </Layout>
  );
};

export default AboutMeText;
