import React from "react";
import { Box } from "@chakra-ui/react";
import { Navigation } from "utils/enum";
import AboutMeText from "./AboutMeText";
import dynamic from "next/dynamic";

const DynamicCertificates = dynamic(() => import("./Certificates"), {
  ssr: false,
});

const AboutMe = () => {
  return (
    <Box
      className="content-visibility"
      bg="white"
      pt={{ base: "3.2rem", md: "9rem" }}
      pb={{ base: "3.2rem", md: "0" }}
      id={Navigation.AboutMe}
    >
      <AboutMeText />
      <DynamicCertificates />
    </Box>
  );
};

export default AboutMe;
