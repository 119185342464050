import React, { useEffect, useMemo, useRef } from "react";
import { isSSR } from "utils";
import type FlickityType from "flickity";
import { Button, Center } from "@chakra-ui/react";
import ChevronIcon from "@img/chevron_up.svg";

interface Props {
  carouselClassName: string;
}
const useCarousel = ({ carouselClassName }: Props) => {
  const carouselRef = useRef<FlickityType | null>(null);

  useEffect(() => {
    const initCarousel = async () => {
      const Flickity = (await import("flickity")).default;

      if (!isSSR) {
        const element = document.querySelector(`.${carouselClassName}`);
        if (element) {
          const flckt = new Flickity(`.${carouselClassName}`, {
            groupCells: true,
            wrapAround: true,
            pageDots: false,
            prevNextButtons: false,
          });

          carouselRef.current = flckt;
        }
      }
    };
    initCarousel();
  }, [carouselClassName]);

  const controls = useMemo(
    () => (
      <Center>
        <Button
          width="4.8rem"
          height="4.8rem"
          p={0}
          variant="outline"
          onClick={() => carouselRef.current?.previous()}
          mr="1.6rem"
          aria-label="Prev slide"
        >
          <ChevronIcon
            aria-label="Prev slide"
            style={{ transform: "rotateZ(-90deg)" }}
          />
        </Button>
        <Button
          width="4.8rem"
          height="4.8rem"
          p={0}
          variant="outline"
          onClick={() => carouselRef.current?.next()}
          aria-label="Next slide"
        >
          <ChevronIcon
            aria-label="Next slide"
            style={{ transform: "rotateZ(90deg)" }}
          />
        </Button>
      </Center>
    ),
    []
  );

  return { controls };
};

export default useCarousel;
