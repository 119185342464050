import { Box, Flex, Text } from "@chakra-ui/react";
import Categories from "components/Categories";
import FormattedDate from "components/FormattedDate";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { urlFor } from "utils";
import { Locale } from "utils/enum";
import { Post } from "utils/types";

export const RecipeItem = ({
  _createdAt,
  categories,
  mainImage,
  title,
  slug,
}: Post) => {
  const { locale } = useRouter();

  return (
    <Flex direction="column">
      <Link href={`/posts/${slug}`} passHref legacyBehavior>
        <a>
          <Box
            cursor="pointer"
            position="relative"
            zIndex={1}
            _hover={{
              ".recipe-title": {
                color: "main.green",
              },
            }}
          >
            <Box mb="1.6rem" className="recipe-image">
              <Image
                src={urlFor(mainImage)
                  .width(700)
                  .height(820)
                  .quality(100)
                  .url()}
                objectFit="cover"
                layout="responsive"
                priority
                alt="recipe"
                width={352}
                height={352}
                quality={100}
              />
            </Box>
            {categories && categories.length > 0 && (
              <Categories categories={categories} />
            )}
            <Text
              textStyle="h32"
              color="text.dark"
              mb="0.8rem"
              cursor="pointer"
              transition="all 0.3s"
              className="recipe-title"
            >
              {title[locale as Locale]}
            </Text>
          </Box>
        </a>
      </Link>
      <Text textStyle="b16" color="text.dark" className="recipe-date">
        <FormattedDate date={_createdAt} locale={locale} />
      </Text>
    </Flex>
  );
};
