import { Box } from "@chakra-ui/react";
import { useEffect, useLayoutEffect, useState } from "react";
import Option1 from "@img/option1.png";
import Option2 from "@img/option2.png";
import Option3 from "@img/option3.png";
import Option4 from "@img/option4.png";
import Option5 from "@img/option5.png";
import DesktopOption from "./DesktopOption";
import MobileOption from "./MobileOption";
import Image from "next/image";
import { useMediaQuery } from 'hooks/useMediaQuery';

export const OPTIONS = [
  <Image src={Option1} key={1} alt="Option" />,
  <Image src={Option2} key={2} alt="Option" />,
  <Image src={Option3} key={3} alt="Option" />,
  <Image src={Option4} key={4} alt="Option" />,
  <Image src={Option5} key={5} alt="Option" />,
];

interface OptionProps {
  title: string;
  subtitle: string;
  details: Detail[];
  index: number;
  description: string;
}

export interface Detail {
  title: string;
  description: string;
  highlighted?: string;
  hint?: string;
  items?: string[];
}
const Option = ({
  title,
  subtitle,
  details,
  index,
  description,
}: OptionProps) => {
  const [renderedComponent, setRenderedComponent] = useState(
    <DesktopOption
      index={index}
      title={title}
      subtitle={subtitle}
      details={details}
      description={description}
    />
  );
  const moreThan768 = useMediaQuery("(min-width: 768px)");

  useLayoutEffect(() => {
    setRenderedComponent(
      moreThan768 ? (
        <DesktopOption
          index={index}
          title={title}
          subtitle={subtitle}
          details={details}
          description={description}
        />
      ) : (
        <MobileOption
          index={index}
          title={title}
          subtitle={subtitle}
          details={details}
          description={description}
        />
      )
    );
  }, [moreThan768, index, title, subtitle, details, description]);

  return (
    <Box
      p={{ base: "2.4rem 2.4rem 3rem 2.4rem", md: "2.4rem 2.4rem 2.4rem 4rem" }}
      backgroundColor="white"
      w="100%"
    >
      {renderedComponent}
    </Box>
  );
};

export default Option;
