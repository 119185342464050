import { Text, TextProps } from "@chakra-ui/react";
import React from "react";

interface Props extends TextProps {
  text?: string;
}

export const HighlightedText = ({ children, text, ...rest }: Props) => (
  <Text
    position="relative"
    fontSize={{ base: "4.8rem", md: "9.8rem" }}
    lineHeight={{ base: "4.8rem", md: "8.8rem" }}
    fontFamily="Carolina"
    fontWeight={400}
    color="main.green"
    ml="clamp(0.8rem, 2vw, 2rem)"
    as="strong"
    {...rest}
  >
    {children || text}
  </Text>
);

export const SimpleText = ({ children, text, ...rest }: Props) => (
  <Text
    fontSize={{ base: "4rem", md: "7.2rem" }}
    lineHeight={{ base: "4.8rem", md: "8.8rem" }}
    fontFamily="Cormorant"
    fontWeight={700}
    color="text.dark"
    {...rest}
  >
    {children || text}
  </Text>
);

interface SectionTitleProps extends TextProps {
  startText: React.ReactNode;
  highlightedText: React.ReactNode;
  endText?: React.ReactNode;
  children?: React.ReactNode;
}

function SectionTitle({
  endText,
  highlightedText,
  startText,
  children,
  ...rest
}: SectionTitleProps) {
  return (
    <SimpleText as="h4" {...rest}>
      {startText}
      <HighlightedText>{highlightedText}</HighlightedText>
      {endText && (
        <>
          <br />
          {endText}
        </>
      )}
      {children}
    </SimpleText>
  );
}

export default SectionTitle;
