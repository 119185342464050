import { Box, Flex, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import Collapsed from "./Collapsed";
import ExpandButton from "./ExpandButton";
import { Detail, OPTIONS } from "./Option";

interface Props {
  index: number;
  title: string;
  description: string;
  subtitle: string;
  details: Detail[];
}

const MobileOption = ({ index, title, subtitle, details, description }: Props) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <Flex justifyContent="space-between">
        <Box>
          <Text
            textStyle={{ base: "h24", md: "h32" }}
            color="text.dark"
            mb="4px"
          >
            {title}
          </Text>
          <Text textStyle={{ base: "b14", md: "b20" }} color="text.grey">
            {subtitle}
          </Text>
        </Box>
        <Box
          flexShrink={0}
          width={{ base: "7.2rem", md: "15.2rem" }}
          height={{ base: "7.2rem", md: "15.2rem" }}
          sx={{ svg: { width: "100%", height: "100%" } }}
        >
          {OPTIONS[index]}
        </Box>
      </Flex>
      <Collapsed details={details} isOpen={isOpen} description={description} />
      <ExpandButton isOpen={isOpen} onToggle={onToggle} />
    </>
  );
};

export default MobileOption;
