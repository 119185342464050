import { Box, Button } from "@chakra-ui/react";
import React from "react";
import ChevronIcon from "@img/chevron_up.svg";
import { useTranslation } from "next-i18next";

interface Props {
  onToggle: () => void;
  isOpen: boolean;
}
const ExpandButton = ({ onToggle, isOpen }: Props) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="outline"
      onClick={onToggle}
      cursor="pointer"
      width={{ base: "100%", md: "auto" }}
      mt="2.4rem"
      aria-label="Show more"
      className="show_more"
    >
      {t("consulting.details")}
      <Box ml="8px" transform={`${isOpen ? "rotate(0deg)" : "rotate(180deg)"}`}>
        <ChevronIcon className="show_more" />
      </Box>
    </Button>
  );
};

export default ExpandButton;
