import { Box, Flex, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import Collapsed from "./Collapsed";
import ExpandButton from "./ExpandButton";
import { Detail, OPTIONS } from "./Option";

interface Props {
  index: number;
  title: string;
  subtitle: string;
  description: string;
  details: Detail[];
}

const DesktopOption = ({ index, title, subtitle, details, description }: Props) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box position="relative" w="100%">
      <Box maxW="calc(100% - 16rem)">
        <Text textStyle={{ base: "h24", md: "h32" }} color="text.dark" mb="4px">
          {title}
        </Text>
        <Text textStyle={{ base: "b14", md: "b20" }} color="text.grey">
          {subtitle}
        </Text>
      </Box>
      <Collapsed details={details} description={description} isOpen={isOpen} />
      <ExpandButton isOpen={isOpen} onToggle={onToggle} />
      <Box
        position="absolute"
        top="0"
        right="0"
        flexShrink={0}
        width={{ base: "7.2rem", md: "15.2rem" }}
        height={{ base: "7.2rem", md: "15.2rem" }}
        sx={{ svg: { width: "100%", height: "100%" } }}
      >
        {OPTIONS[index]}
      </Box>
    </Box>
  );
};

export default DesktopOption;
