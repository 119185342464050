import { Box, Button, Collapse, Flex, Text, VStack } from "@chakra-ui/react";
import { useContactMeModal } from "hooks/useContactMeModalCtx";
import { useTranslation } from "next-i18next";
import React from "react";
import { Detail } from "./Option";

interface Props {
  isOpen: boolean;
  details: Detail[];
  description: string;
}
const Collapsed = ({ isOpen, details, description }: Props) => {
  const { onOpen } = useContactMeModal();

  const { t } = useTranslation();
  return (
    <Collapse in={isOpen}>
      <VStack
        mt={{ base: "2.4rem", md: "8rem" }}
        spacing={{ base: "1.6rem", md: "2.4rem" }}
        alignItems="flex-start"
      >
        <Text
          textStyle={{ base: "b16r" }}
          color="text.grey"
          display="inline"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {details?.map(({ title, highlighted, hint, items }, index) => {
          return (
            <Box key={index}>
              <Box>
                <Text
                  textStyle={{ base: "b16", md: "b20" }}
                  color="text.grey"
                  display="inline"
                >
                  {title}
                </Text>
                {highlighted && (
                  <Text
                    display="inline"
                    fontSize={{ base: "1.6rem", md: "2rem" }}
                    color="main.green"
                    fontWeight="500"
                    whiteSpace="nowrap"
                    dangerouslySetInnerHTML={{ __html: "&nbsp" + highlighted }}
                  />
                )}
              </Box>
              {hint && (
                <Text
                  textStyle="b16r"
                  dangerouslySetInnerHTML={{ __html: hint }}
                  sx={{
                    strong: {
                      color: "main.green",
                      fontWeight: "500",
                    },
                  }}
                />
              )}
              {items && (
                <Box mt={{ base: "0.8rem", md: "1.6rem" }}>
                  {items?.map((item, index) => (
                    <Text
                      textStyle={{ base: "b16r" }}
                      color="text.grey"
                      key={index}
                    >
                      - {item}
                    </Text>
                  ))}
                </Box>
              )}
            </Box>
          );
        })}
      </VStack>
      <Button
        width={{ base: "100%", md: "auto" }}
        variant="solid"
        mt={{ base: "2.4rem", md: "4rem" }}
        onClick={onOpen}
        aria-label="Get consultation"
      >
        {t("consulting.option.get_consult")}
      </Button>
    </Collapse>
  );
};

export default Collapsed;
