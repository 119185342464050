import React from "react";
import { Box } from "@chakra-ui/react";
import Requests from "./Requests";

const CanHelpWithRequests = () => {
  return (
    <Box
      className="content-visibility"
      bg="white"
      pt={{ base: "3.2rem", md: "9rem" }}
      pb={{ base: "3.2rem", md: "7.7rem" }}
    >
      <Requests />
    </Box>
  );
};

export default CanHelpWithRequests;
