import { Box, Button, Container, Flex, Text, VStack } from "@chakra-ui/react";
import Layout from "components/Layout";
import SectionTitle from "components/SectionTitle";
import { useTranslation } from "next-i18next";
import React from "react";

import { Navigation } from "utils/enum";
import { useContactMeModal } from "hooks/useContactMeModalCtx";
import Option from "./Option";

const ConsultingOptions = () => {
  const { t } = useTranslation();
  const { onOpen } = useContactMeModal();

  const options = t("consulting.options", { returnObjects: true }) as any;

  return (
    <Box
      position="relative"
      className="content-visibility lazy-background"
      pt={{ base: "3.2rem", lg: "12rem" }}
      bg="bg.beige"
      backgroundPosition={{ base: "120% 10%", md: "center right" }}
      bgSize={{ md: "60rem", base: "30rem" }}
      backgroundRepeat="no-repeat"
      id={Navigation.Consulting}
      sx={{
        "&.visible": {
          backgroundImage: "url('/img/consultations.svg')",
        },
      }}
    >
      <Layout>
        <Container maxW="73.6rem" p="0">
          <Box mb={{ base: "2.4rem", lg: "6.4rem" }}>
            <SectionTitle
              startText={t("consulting.title.options")}
              highlightedText={t("consulting.title.online")}
              endText={t("consulting.title.conlsulting")}
            />
          </Box>
          <VStack spacing="8px" mb={{ base: "5rem", md: "9rem" }}>
            {options?.map((option: any, index: number) => (
              <Option key={index} {...option} index={index} />
            ))}
          </VStack>
        </Container>
        <Flex position="relative" direction="column" alignItems="center">
          <Box
            position="absolute"
            bottom={{ base: "4rem", md: 0, lg: "-6rem" }}
          >
            <Text
              fontSize={{ xl: "20rem", lg: "16rem", md: "12rem", base: "7rem" }}
              fontFamily="Carolina"
              color="main.green"
              opacity={0.1}
              whiteSpace="nowrap"
            >
              {t("text_me")}
            </Text>
          </Box>
          <Container maxW="62.7rem">
            <Text
              textStyle={{ base: "h24", md: "h32" }}
              color="text.dark"
              mb="4rem"
              textAlign="center"
            >
              {t("consulting.footer.title")}
            </Text>
          </Container>
          <Button onClick={onOpen} aria-label="Contact me">
            {t("welcome.contact_me")}
          </Button>
        </Flex>
      </Layout>
    </Box>
  );
};

export default ConsultingOptions;
