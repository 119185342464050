import { Box, Button, Center, Grid } from "@chakra-ui/react";
import Layout from "components/Layout";
import SectionTitle from "components/SectionTitle";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import React, { useLayoutEffect, useState } from "react";
import { Post } from "utils/types";
import DesktopRecipes from "./Desktop";
import MobileRecipes from "./Mobile";
import { useMediaQuery } from "hooks/useMediaQuery";

const Recipes = ({ posts }: { posts: Post[] }) => {
  const { t } = useTranslation();
  const { push } = useRouter();
  const moreThan768 = useMediaQuery("(min-width: 768px)");

  const [renderedComponent, setRenderedComponent] = useState(
    <MobileRecipes posts={posts} />
  );

  useLayoutEffect(() => {
    setRenderedComponent(
      moreThan768 ? (
        <DesktopRecipes posts={posts} />
      ) : (
        <MobileRecipes posts={posts} />
      )
    );
  }, [moreThan768, posts]);

  return (
    <Box
      pt={{ base: "3.2rem", md: "12rem" }}
      bg="white"
      pb={{ base: "3.2rem", md: "12rem" }}
    >
      <Layout>
        <Box mb={{ base: "2.4rem", md: "8rem" }} textAlign="center">
          <SectionTitle
            startText={t("recipes.title.healthy")}
            highlightedText={t("recipes.title.recipes")}
            endText={t("recipes.title.in_my_blog")}
          />
        </Box>
        {renderedComponent}

        <Center>
          <Button
            variant="outline"
            p="1.7rem 6.4rem"
            onClick={() => push("/posts")}
            aria-label="Open blog page"
          >
            {t(`recipes.go_to_blog`)}
          </Button>
        </Center>
      </Layout>
    </Box>
  );
};

export default Recipes;
