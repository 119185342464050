import dayjs from "dayjs";
import "dayjs/locale/ru";
import "dayjs/locale/uk";
import "dayjs/locale/en";

import { Locale } from "utils/enum";

interface Props {
  date: Date;
  locale?: string;
}

const localeMapping = {
  uk: "uk",
  ru: "ru",
  en: "en",
  default: "en",
};
const FormattedDate = ({ date, locale }: Props) => {
  return (
    <>
      {dayjs(date)
        .locale(localeMapping[locale as Locale])
        .format("D MMMM YYYY")}
    </>
  );
};

export default FormattedDate;
