import { Box, Button, Container, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import React from "react";
import InstagramIcon from "@img/instagram.svg";
import TelegramIcon from "@img/telegram.svg";

const Social = () => {
  const { t } = useTranslation();
  return (
    <Flex
      className="content-visibility"
      direction="column"
      alignItems="center"
      background="white"
      pb={{ base: "3.2rem", md: "12rem" }}
      position="relative"
    >
      <Box
        position="absolute"
        top={{ base: "50%", md: "0" }}
        transform={{ base: "translateY(-50%)", md: "none" }}
      >
        <Text
          fontSize={{ base: "7rem", md: "13rem", lg: "20rem" }}
          lineHeight={{ base: "28.2rem", md: "18.2rem" }}
          fontFamily="Carolina"
          color="main.green"
          opacity={0.1}
        >
          {t("subscribe")}
        </Text>
      </Box>
      <Container
        maxW={{ base: "31rem", md: "62.7rem" }}
        mb={{ base: "4rem", md: "5rem" }}
      >
        <Text
          textStyle={{ base: "h24", md: "h32" }}
          color="text.dark"
          textAlign="center"
        >
          {t("recipes.subscibe")}
        </Text>
      </Container>
      <Flex gap="2.4rem">
        <Button
          variant="solid"
          bg="main.green"
          p="1.2rem"
          aria-label="Daria's instagram"
          onClick={() =>
            window.open(
              "https://www.instagram.com/daria_nutrition_ua/",
              "_blank"
            )
          }
        >
          <InstagramIcon />
        </Button>
        {/* <Button
          variant="solid"
          bg="main.green"
          p="1.2rem"
          onClick={() => push("#instgram")}
        >
          <TelegramIcon />
        </Button> */}
      </Flex>
    </Flex>
  );
};

export default Social;
