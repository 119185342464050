import { Grid } from "@chakra-ui/react";
import React from "react";
import { Post } from "utils/types";
import { RecipeItem } from "./RecipeItem";

interface Props {
  posts: Post[];
}
const DesktopRecipes = ({ posts }: Props) => {
  return (
    <Grid
      mb="4rem"
      gridTemplateColumns={`repeat(auto-fit, minmax(200px, 1fr))`}
      gap="3.2rem"
    >
      {posts.map((item, index) => (
        <RecipeItem {...item} key={index} />
      ))}
    </Grid>
  );
};

export default DesktopRecipes;
