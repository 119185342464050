import { Box, Grid } from "@chakra-ui/react";
import useCarousel from "hooks/useCarousel";
import React from "react";
import { Post } from "utils/types";
import { RecipeItem } from "./RecipeItem";

interface Props {
  posts: Post[];
}
const MobileRecipes = ({ posts }: Props) => {
  const { controls } = useCarousel({ carouselClassName: "recipe-carousel" });
  return (
    <Box mb="4rem">
      <Box
        className="recipe-carousel"
        mb={"2.4rem"}
        sx={{
          ".flickity-cell": {
            width: "calc(100% - 0.8rem)",
            marginRight: "0.8rem",
          },
        }}
      >
        {posts.map((item, index) => (
          <RecipeItem {...item} key={index} />
        ))}
      </Box>
      {controls}
    </Box>
  );
};

export default MobileRecipes;
