import { Box, Button, Flex, Text } from "@chakra-ui/react";
import Image from "next/image";
import React from "react";
import avatar from "@img/ava.png";
import { useTranslation } from "next-i18next";
import Layout from "components/Layout";
import { Navigation } from "utils/enum";
import useHeaderHeight from "hooks/useHeaderHeight";
import { useContactMeModal } from "hooks/useContactMeModalCtx";
import SectionTitle from "components/SectionTitle";

const AVATAR_PT = 50;

function Welcome() {
  const { t } = useTranslation();
  const { onOpen } = useContactMeModal();

  const headerHeight = useHeaderHeight();

  return (
    <Box
      bg="bg.beige"
      minH={{
        base: "unset",
        md: `calc(var(--app-height) - ${headerHeight}px)`,
      }}
      // minH={"calc(100vh - env(safe-area-inset-bottom))"}
      pb="10rem"
      pt={AVATAR_PT || 0}
      backgroundImage={{
        base: "url(/img/rose5.svg), url(/img/rose6.svg)",
        md: "url(/img/welcome1.svg), url(img/welcome2.svg)",
      }}
      backgroundRepeat="no-repeat"
      backgroundPosition={{
        base: "bottom left, right bottom, 90% bottom",
        md: "bottom left, bottom right",
      }}
    >
      <Layout>
        <Flex
          id={Navigation.Main}
          direction="column"
          justifyItems="center"
          alignItems="center"
        >
          <Image
            src={avatar}
            quality={100}
            width={205}
            height={205}
            alt="Me"
            style={{ zIndex: 1 }}
          />
          <Box textAlign="center">
            <SectionTitle
              startText={t("common:welcome.title.start")}
              highlightedText={t("welcome.title.health")}
              endText={t("welcome.title.lifestyle")}
            />
          </Box>
          <Box mb="5rem" mt="1.2rem">
            <Text
              textStyle={{ base: "b16", md: "b20" }}
              color="text.grey"
              as="h1"
              textAlign="center"
            >
              {t("welcome.subtitle")}
            </Text>
          </Box>
          <Button variant="solid" onClick={onOpen} aria-label="Contact me">
            {t("welcome.contact_me")}
          </Button>
        </Flex>
      </Layout>
    </Box>
  );
}

export default Welcome;
