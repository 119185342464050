import { Box, Container, Flex, Text } from "@chakra-ui/react";
import Layout from "components/Layout";
import SectionTitle from "components/SectionTitle";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import React from "react";
import useCarousel from "hooks/useCarousel";
import { Review } from "utils/types";
import { Locale } from "utils/enum";
import { useRouter } from "next/router";

interface Props {
  reviews: Review[];
}
const Reviews = ({ reviews }: Props) => {
  const { t } = useTranslation();
  const { controls } = useCarousel({ carouselClassName: "main-carousel" });

  return (
    <Box
      className="content-visibility lazy-background"
      pt={{ base: "3.2rem", md: "12rem" }}
      bg="bg.beige"
      bgRepeat="no-repeat"
      bgSize={{ base: "27rem", md: "40rem" }}
      bgPosition={{ base: "center right", xl: "50px calc(100% - 20px)" }}
      pb={{ base: "3.2rem", md: "12rem" }}
      sx={{
        "&.visible": {
          backgroundImage: "url(/img/reviews.svg)",
        },
      }}
    >
      <Layout>
        <Container maxW="73.6rem" mb={{ base: "2.4rem", md: "8rem" }}>
          <SectionTitle
            startText={t("reviews.title.reviews")}
            highlightedText={t("reviews.title.about_my")}
            endText={t("reviews.title.work")}
          />
        </Container>
      </Layout>
      <Box className="main-carousel" mb={{ base: "2.4rem", md: "4rem" }}>
        {reviews.map((review) => (
          <Review {...review} key={review._id} />
        ))}
      </Box>
      {controls}
    </Box>
  );
};

const Review = ({ avatarUrl, name, review }: Review) => {
  const { locale } = useRouter();
  return (
    <Box
      p={{ base: "3.2rem 2.4rem 2.4rem", md: "4.8rem 3.2rem" }}
      bg="white"
      w={{ base: "31rem", md: "35.2rem" }}
      mr={{ base: "0.8rem", md: "3.2rem" }}
    >
      <Text textStyle={{ base: "b16" }} color="text.dark" mb="2.4rem">
        {review[locale as Locale]}
      </Text>
      <Flex alignItems="center">
        <Box
          flexShrink={0}
          w={{ base: "3.2rem", md: "4rem" }}
          h={{ base: "3.2rem", md: "4rem" }}
          borderRadius="50%"
          overflow="hidden"
        >
          <Image
            src={avatarUrl}
            width={40}
            height={40}
            alt="Review avatar"
            objectFit="cover"
          />
        </Box>
        <Text
          textStyle={{ base: "b16", md: "b20" }}
          color="text.dark"
          ml="1.6rem"
        >
          {name[locale as Locale]}
        </Text>
      </Flex>
    </Box>
  );
};
export default Reviews;
