import { Flex, Tag } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React from "react";
import { capitalize } from "utils";
import { Locale } from "utils/enum";
import { Reference } from "utils/types";

interface Props {
  categories: Reference[];
}
const Categories = ({ categories }: Props) => {
  const { locale } = useRouter();
  return (
    <Flex flexWrap="wrap" gap="4px" mb="0.8rem" className="recipe-categories">
      {categories.map(({ title }, index) => (
        <Tag size="md" key={index}>
          {capitalize(title?.[locale as Locale])}
        </Tag>
      ))}
    </Flex>
  );
};

export default Categories;
