import { Box, Container, Flex, Text } from "@chakra-ui/react";
import Layout from "components/Layout";
import SectionTitle from "components/SectionTitle";
import { useTranslation } from "next-i18next";

const Requests = () => {
  const { t } = useTranslation("common");

  const requests = t("requests", { returnObjects: true }) as any;

  return (
    <Box
      mb={{ base: "3rem", md: "6.4rem" }}
      background="url(/img/hands.svg) no-repeat"
      backgroundPosition="top right"
      bgSize={{ base: "15rem", md: "40rem" }}
    >
      <Layout>
        <Container maxW="73.6rem" p="0">
          <Box mb={{ base: "2.4rem", md: "4rem" }} textAlign="center">
            <Box textAlign={{ base: "left", lg: "center" }}>
              <SectionTitle
                startText={t("requests.title.start")}
                highlightedText={t("requests.title.highlighted")}
                endText={t("requests.title.end")}
              />
            </Box>
          </Box>
          <Flex gap="0.8rem" direction="column">
            {requests?.map((req: any) => (
              <Box
                p={{ base: "1.6rem", md: "2.4rem 3.2rem" }}
                key={req}
                bg="bg.beige"
              >
                <Text
                  textStyle={{ base: "b16r", md: "b20" }}
                  color="text.dark"
                  textAlign="center"
                >
                  {req}
                </Text>
              </Box>
            ))}
          </Flex>
        </Container>
      </Layout>
    </Box>
  );
};

export default Requests;
