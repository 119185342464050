import type { GetStaticProps, NextPage } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Header from "components/Header";
import { sanityClient } from "utils";
import Welcome from "pages/home/Welcome";
import AboutMe from "pages/home/AboutMe";
import ConsultingOptions from "pages/home/ConsultingOptions";
import Reviews from "pages/home/Reviews";
import Recipes from "pages/home/Recipes";
import Footer from "components/Footer";
import { Post, Review } from "utils/types";
import Social from "pages/home/Social";
import Head from "next/head";
import { useTranslation } from "next-i18next";
import { Locale } from "utils/enum";
import MetaLocaleLinks from "components/MetaTags/MetaLinks";
import CanHelpWithRequests from "pages/home/CanHelpWithRequests";

interface Props {
  posts: Post[];
  reviews: Review[];
  locale: Locale;
}

const Home: NextPage<Props> = ({ posts, reviews, locale }) => {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>{t("welcome.subtitle")}</title>
        <meta name="description" content={t("home.description") || ""} />
      </Head>
      <MetaLocaleLinks url="" locale={locale} />
      <Header />
      <Welcome />
      <CanHelpWithRequests />
      <ConsultingOptions />
      <Reviews reviews={reviews} />
      <AboutMe />
      <Recipes posts={posts} />
      <Social />
      <Footer />
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const posts = await sanityClient.fetch<Post[]>(`
      *[_type == "post"]| order(_createdAt desc)[0...3] {
        _id,
        'mainImage': mainImage.asset -> url,
        title,
        _createdAt,
        categories[]->{title},
        'slug' : slug.current
      }
  `);

  const reviews = await sanityClient.fetch(`
      *[_type == "review"] | order(_createdAt desc) {
        _id,
        'avatarUrl': avatar.asset -> url,
        name,
        review,
      }
  `);

  return {
    revalidate: 60 * 60 * 24,
    props: {
      posts,
      reviews,
      locale,
      ...(locale && (await serverSideTranslations(locale, ["common"]))),
    },
  };
};

export default Home;
